var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row offers mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h4', {
    staticClass: "h4 title text-center pt-1 mt-1"
  }, [_vm._v(" Generate SO (Sales Order) 365 ")]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Format Report So 365 ( untuk kebutuhan upload / manual ) di pecah berdasarkan orderline. ( Exclude Payment Free, Wallet, Gramedia.com ). ")]), _vm.isError ? _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong please Check Form "), _vm._l(_vm.errorMessageSo, function (m) {
    return _c('div', {
      key: m.param
    }, [_c('li', [_vm._v(_vm._s(m))])]);
  })], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-md-2"
  }, [_vm._v("Select Range")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "date",
      "masks": _vm.masks,
      "max-date": new Date(),
      "is-range": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents,
          isDragging = _ref.isDragging;
        return [_c('div', {
          staticClass: "rows"
        }, [_c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.start
          }
        }, inputEvents.start)), _c('span', {
          staticClass: "p-2"
        }, [_vm._v("to")]), _c('input', _vm._g({
          staticClass: "flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.end
          }
        }, inputEvents.end))])];
      }
    }]),
    model: {
      value: _vm.range,
      callback: function ($$v) {
        _vm.range = $$v;
      },
      expression: "range"
    }
  })], 1)]), _c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-md-2"
  }, [_vm._v(" Offers ")]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.offerType,
      expression: "Form.offerType"
    }],
    staticClass: "form-control-sm",
    attrs: {
      "required": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.Form, "offerType", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.Offers, function (offer) {
    return _c('option', {
      key: offer.value,
      domProps: {
        "value": offer.value
      }
    }, [_vm._v(" " + _vm._s(offer.name) + " ")]);
  }), 0)]), _c('span', {
    staticClass: "col-md-1"
  }, [_vm._v(" Client ")]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.Form.client_id,
      expression: "Form.client_id"
    }],
    staticClass: "form-control-sm",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.Form, "client_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.clientOption, function (client) {
    return _c('option', {
      key: client.value,
      domProps: {
        "value": client.value
      }
    }, [_vm._v(" " + _vm._s(client.name) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-md-2"
  }, [_vm._v("Publisher")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('multiselect', {
    attrs: {
      "options": _vm.vendors,
      "placeholder": "Select one",
      "label": "Vendor Name",
      "close-on-select": true,
      "custom-label": _vm.customLabel,
      "searchable": true,
      "loading": _vm.isLoadingSearchVendor,
      "track-by": "name"
    },
    on: {
      "search-change": _vm.asyncFind
    },
    model: {
      value: _vm.Form.vendor_id,
      callback: function ($$v) {
        _vm.$set(_vm.Form, "vendor_id", $$v);
      },
      expression: "Form.vendor_id"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [!_vm.isLoading ? _c('button', {
    staticClass: "btn btn-block btn-primary",
    on: {
      "click": _vm.actionSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]) : _c('button', {
    staticClass: "btn btn-block btn-primary",
    attrs: {
      "disabled": ""
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }, [_vm._v(" Loading ... ")])])]), _c('b-overlay', {
    staticClass: "d-inline-block",
    attrs: {
      "show": _vm.busy,
      "rounded": "",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    },
    on: {
      "hidden": _vm.onHidden
    }
  }, [_c('div', {
    staticClass: "col justify-content-end d-flex align-items-end"
  }, [_c('button', {
    staticClass: "btn btn-md btn-primary",
    attrs: {
      "disabled": _vm.busy
    },
    on: {
      "click": _vm.actionDownload
    }
  }, [_c('i', {
    staticClass: "fa fa-download"
  })])])])], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-auto"
  }, [_vm._v(" Total Balance "), _c('b', [_vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.totalAmount)) + " ")])]), _c('div', {
    staticClass: "col-md-auto"
  }, [_vm._v(" Total Rows "), _c('b', [_vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.totalRows)) + " ")])])]), _c('b-table', {
    attrs: {
      "sticky-header": "700px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "responsive": "sm",
      "hover": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(offerName)",
      fn: function (row) {
        return [row.item.offerTypeID != 4 ? _c('div', {
          staticStyle: {
            "width": "27em"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: ("/subledger/detail/" + (row.item.custPurchaseOrder))
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.item.offerName) + " ")])], 1) : _c('div', [_vm._v(" " + _vm._s(row.item.offerName) + " ")])];
      }
    }, {
      key: "cell(custAccount)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(row.item.custAccount) + " ")])];
      }
    }, {
      key: "cell(itemId)",
      fn: function (row) {
        return [_vm.isChar(row.item.itemId) ? _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(row.item.itemId) + " ")]) : _c('div', [_vm._v(" " + _vm._s(row.item.itemId) + " ")])];
      }
    }, {
      key: "cell(salesPrice)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.salesPrice, "IDR")) + " ")])];
      }
    }, {
      key: "cell(salesRecieptDateRequest)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(new Date(row.item.salesRecieptDateRequest).toDateString()) + " ")])];
      }
    }, {
      key: "cell(originalOrderNumber)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: ("/order/detail?ordernumber=" + (row.item.originalOrderNumber))
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.originalOrderNumber) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('div', [_c('blockquote', {
    staticClass: "blockquote text-center"
  }, [_c('p', {
    staticClass: "mb-0 font-sm"
  }, [_vm._v(" This Page for preview only, please download to get full data format sales order detail for upload to microsoft dynamic 365 ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Gramedia Digital "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Finance Report ")])])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }